<template>
  <vs-card>
    <vs-form autocomplete="off">
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h3 class="font-bold">Create Category</h3>
          </div>
          <div class="w-full m-5" align="right">
            <vs-button
              :disabled="!validateForm"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
              >Save</vs-button
            >
            <vs-button @click="cancelHandler" type="border"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-divider />
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <vs-input
              :success="!errors.first('basic.name') && categoryData.name != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.name') ? true : false"
              v-validate="'required'"
              name="name"
              data-vv-as="Name"
              label="Category Name"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.name')"
              v-model="categoryData.name"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <vs-input
              :success="!errors.first('basic.slug') && categoryData.slug != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.slug') ? true : false"
              v-validate="{
                required: true,
                regex: /^[a-zA-Z0-9_\-\\.]*$/i
              }"
              name="slug"
              data-vv-as="slug"
              label="Category Slug"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.slug')"
              v-model="categoryData.slug"
              placeholder="Example-slug"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full sm:w-1/2 m-5">
            <label class="vs-input--label">Parent Category - Optional</label>
            <!-- :danger="errors.first('parentCategory') ? true : false"
            :success="
              !errors.first('parentCategory') &&
              categoryData.parentCategory !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'" -->
            <vs-select
              data-vv-as="Parent Category"
              name="Parent Category"
              autocomplete
              v-model="categoryData.parentCategory"
              class="w-full"
              @change="categoryData.parentCategory === 'None' ? '' : categoryData.isPopular = false"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in parentCategoryItem"
              />
            </vs-select>
            <span class="text-danger" style="font-size: 0.65rem !important">
              {{ errors.first("parentCategory") }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <label class="vs-input--label">Category Description</label>
            <vue-ckeditor v-model="categoryData.productDescription" :config="config"/>
            <!-- <vs-textarea
              :success="
                !errors.first('basic.productDescription') &&
                categoryData.productDescription != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.productDescription') ? true : false"
              name="Product Description"
              data-vv-as="productDescription"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.productDescription')"
              v-model="categoryData.productDescription"
            /> -->
          </div>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <label class="vs-input--label">Matching Condition</label>
            <div>
              <vs-radio
                class="mr-5"
                v-model="categoryData.matchingCondition"
                vs-value="any"
                >Any</vs-radio
              >
              <vs-radio v-model="categoryData.matchingCondition" vs-value="all"
                >All</vs-radio
              >
            </div>
          </div>
        </vs-col> -->
      </vs-row>
      <vs-divider />
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="h-[500px]">
        <div class="m-5">
          <p class="text-2xl pb-2"><strong>Category Image</strong> - Optional</p>
          <p class="text-sm">When uploading a category images, please upload both a mobile and desktop image to 
            <br/> ensure that the experience in store is accurate across all device types. </p>
        </div>
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="12"
          class="vs-con-loading__container flex flex-col"
        >
        <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
          <label class="text-base font-semibold">Desktop Image 1280x220</label>
          <img v-if="desktopImageUrl" :src="desktopImageUrl" alt class="img-fluid" />
          <div v-else id="desktop" class="customHeight w-3/4 sm:w-1/2 text-center relative" key="asdf">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
              h-full w-full absolute flex flex-col gap-3 
              items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'desktop')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputDesktop" hidden @change="handleFileChange($event, 'desktop')" accept="image/*" />
              <vs-button @click="uploadDesktopImage" class="bg-primary2 w-1/2" :key="1">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">1280 x 220</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3 items-start justify-between m-5 w-full h-full">
          <label class="text-base font-semibold">Mobile Image 360x220</label>
          <img v-if="mobileImageUrl" :src="mobileImageUrl" alt class="img-fluid" />
          <div v-else id="mobile" class="customHeight w-3/4 sm:w-1/2 text-center relative">
            <div
              class="border-2 border-dashed border-gray-500 p-5 
                h-full w-full absolute flex flex-col gap-3 
                items-center justify-center"
              @dragover.prevent
              @drop="handleDrop($event, 'mobile')"
            >
              <feather-icon icon="UploadIcon" class="text-primary2" />
              <p class="text-primary2 text-xl">Drop files to upload</p>
              <p class="text-primary2 text-lg">or</p>
              <input type="file" ref="fileInputMobile" hidden @change="handleFileChange($event, 'mobile')" accept="image/*" />
              <vs-button @click="uploadMobileImage" class="bg-primary2 w-1/2" :key="2">Select Files</vs-button>
              <p class="text-lg font-light">Recommended Image Size</p>
              <p class="text-lg font-light">360 x 220</p>
            </div>
          </div>
        </div>
          <!-- <div class="w-full m-5">
            <label>Upload Image</label>
            <div class="mt-6">
              <div
                class="mb-4 large-square-img-wrapper"
                v-if="categoryData.image"
                style="margin: auto"
              >
                <img :src="categoryData.image" class="img-fluid" alt />
              </div>
              <div style="text-align: center; position: relative; height: 45px">
                <label
                  class="image-upload"
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  Upload an image
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event, 'profileImageUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div> -->
        </vs-col>
      </vs-row>
      <vs-divider />
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <div class="m-5">
          <p class="text-2xl pb-2 font-bold">Category Settings</p>
        </div>
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <label class="vs-input--label text-primary2"><strong>Popular category</strong> (Need to set Parent Category to None)</label>
            <vs-checkbox :disabled="categoryData.parentCategory !=='None' " v-model="categoryData.isPopular" class="mt-2">Checking this will display this category in the top navigation on desktop, and in the popular section on the mobile navigation.</vs-checkbox>
          </div>
        </vs-col> -->
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6"> -->
          <div class="w-full m-5">
            <label class="vs-input--label text-primary2" style="font-weight: 600"
              >Category Visibility</label
            >
            <div class="w-full py-5 pl-3">
              <vs-radio
                class="mr-5"
                v-model="categoryData.categoryVisibility"
                vs-value="public"
              >
                <p
                  style="
                    margin-bottom: 3px;
                    font-weight: 600;
                    padding-top: 8px;
                    margin-left: 10px;
                  "
                >
                  Public
                </p>
                <p
                  style="
                    margin-bottom: 3px;
                    padding-top: 8px;
                    margin-left: 10px;
                  "
                >
                  Default Visibility. This will allow all clinics to view this
                  category.
                </p>
              </vs-radio>
            </div>
            <div class="w-full pt-5 pl-3">
              <vs-radio
                v-model="categoryData.categoryVisibility"
                vs-value="private"
                ><p
                  style="
                    margin-bottom: 3px;
                    font-weight: 600;
                    padding-top: 8px;
                    margin-left: 10px;
                  "
                >
                  Private
                </p>
                <p
                  style="
                    margin-bottom: 3px;
                    padding-top: 8px;
                    margin-left: 10px;
                  "
                >
                  This will only allow specific users associated to this
                  category to view it.
                </p></vs-radio
              >
            </div>
            <div class="w-4/5 ml-12 my-2" v-if="categoryData.categoryVisibility === 'private'">
            <label>Assign Clinics</label>
              <v-select
                :closeOnSelect="false"
                autocomplete
                @input-change="onSearch($event.target.value)"
                :options="clinics"
                v-model="categoryData.associatedClinicIds"
                multiple
              ></v-select>
            </div>
          </div>
        <!-- </vs-col> -->
      <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-checkbox v-model="categoryData.isPopular" class="mx-8 mt-24 sm:mt-16 text-base" >
            Popular Category
          </vs-checkbox>
        </div>
      </vs-col> -->
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import axios from "@/axios";
import VueCkeditor from 'vue-ckeditor2';

export default {
  name: "AddCategory",
  components: {
    vSelect,
    VueCkeditor,
  },
  props: {
    categoryDetail: {
      type: Object,
      default: () => {},
    },
    update: {
      type: String,
    },
  },
  data() {
    return {
      categoryData: this.categoryDetail,
      parentCategoryItem: [],
      clinics: [],
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
        ],
        versionCheck: false,
        height: 300
      },
      imageType: '',
      desktopImageUrl: '',
      mobileImageUrl: '',
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
  },
  mounted() {},
  methods: {
    ...mapActions("category", ["getParentCategoryList"]),
    ...mapActions("clinic", ["fetchAllClinics"]),
    onSearch(val) {
      console.log(val);
    },
    handleDrop(event, type) {
      event.preventDefault();
      this.imageType = type;
      this.uploadImage(event.dataTransfer.files[0]);
    },
    handleFileChange(event, type) {
      this.imageType = type;
      this.uploadImage(event.target.files[0]);
    },
    uploadDesktopImage() {
      this.$refs.fileInputDesktop.click();
    },
    uploadMobileImage() {
      this.$refs.fileInputMobile.click();
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + this.imageType,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (this.imageType === "desktop") {
            self.categoryData.desktopImage = res.data.Location;
            this.desktopImageUrl = res.data.Location;
          } else if (this.imageType === "mobile") {
            self.categoryData.mobileImage = res.data.Location;
            this.mobileImageUrl = res.data.Location;
          }
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + this.imageType + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      let newData = { ...this.categoryData };
      if (newData.associatedClinicIds) {
        newData = {
          ...newData,
          associatedClinicIds: newData.associatedClinicIds.map(({ value }) => ({
            _id: value,
          })),
        };
      }
      //Set matchingCondition to all by default
      newData.matchingCondition = "all";
      this.$emit("saveDetail", newData);
    },
    cancelHandler() {
      this.$emit("cancelCreate");
    },
    fetchParentCategoryList() {
      this.getParentCategoryList().then((res) => {
        let list = res.data.data;
        let defaultValue = { label: "None", value: "None" };
        this.parentCategoryItem = list.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
        this.parentCategoryItem.unshift(defaultValue);
        this.categoryData.parentCategory = this.categoryData.parentCategory
          ? this.categoryData.parentCategory
          : "None";
      });
    },
  },
  async created() {
    await this.fetchParentCategoryList();
    await this.fetchAllClinics()
      .then(async (res) => {
        this.clinics = res.data.data.map(
          ({ _id: value, clinicName: label }) => ({
            label,
            value,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
.customHeight {
  height: 30dvh;
}
</style>
